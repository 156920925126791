// 生产环境导入组件
// module.exports = file => () => import('@/views/' + file + '.vue')

module.exports = file => {
    return map[file] || null
}

const map = {
    'Home': () => import('@/views/home/index.vue'),
    'Login': () => import('@/views/auth/login.vue'),
    'Registration': () => import('@/views/auth/registration.vue'),
    'ForgetPasswd': () => import('@/views/auth/forget-passwd.vue'),
    'Price': () => import('@/views/price/index.vue'),
    'Product': () => import('@/views/product/index.vue'),
    'Article': () => import('@/views/common/article.vue'),
    'My': () => import('@/views/my/index.vue'),
    '401': () => import('@/views/error-page/401.vue'),
    '404': () => import('@/views/error-page/404.vue'),
}
