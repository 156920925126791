import Cookies from 'js-cookie'
import { getLanguage } from '@/lang/index'
import * as mutationTypes from '../mutation-type.js'

const state = {
    lang: getLanguage()
}

const mutations = {
    [mutationTypes.SET_LANGUAGE]: (state, data) => {
        state.lang = data
        Cookies.set('lang', data)
    }
}

const actions = {
    setLanguage({ commit }, language) {
        commit(mutationTypes.SET_LANGUAGE, language)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}