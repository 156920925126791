/**
 * 公共状态过滤器
 */
const emptyDataFilter = (val, description = '暂无发现数据哦～') => {
	return !!val ? val : description;
}

/**
 * 数据脱敏
 * @param {Object} e
 * @param {Object} start 前面保留几个字符串
 * @param {Object} end 后面保留几个字符串
 */
const dataDesensitizationFilter = (value, start = 3, end = 4) => {
	if (value) {
		const valueArray = value.split("");
		for (let i = start; i < valueArray.length - end; i++) {
			valueArray[i] = "*";
		}
		return valueArray.join("")
	}
	return value
}

module.exports = {
	emptyDataFilter,
	dataDesensitizationFilter
}