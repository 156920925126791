import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
const _import = require('@/router/_import_' + process.env.NODE_ENV)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Home',
        component: _import('Home'),
        meta: {
          title: 'Home',
          auth: false
        }
      }
    ]
  },
  {
    path: '/login',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Login',
        component: _import('Login'),
        meta: {
          title: 'Login',
          auth: false
        }
      }
    ]
  },
  {
    path: '/registration',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Registration',
        component: _import('Registration'),
        meta: {
          title: 'Registration',
          auth: false
        }
      }
    ]
  },
  {
    path: '/forget-passwd',
    component: Layout,
    children: [
      {
        path: '',
        name: 'ForgetPasswd',
        component: _import('ForgetPasswd'),
        meta: {
          title: 'ForgetPasswd',
          auth: false
        }
      }
    ]
  },
  {
    path: '/price',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Price',
        component: _import('Price'),
        meta: {
          title: 'Price',
          auth: false
        }
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Product',
        component: _import('Product'),
        meta: {
          title: 'Product',
          auth: false
        }
      }
    ]
  },
  {
    path: '/article',
    component: Layout,
    children: [
      {
        path: '',
        component: _import('Article'),
        name: 'Article',
        meta: {
          title: 'Article',
          auth: false
        }
      }
    ]
  },
  {
    path: '/my',
    component: Layout,
    children: [
      {
        path: '',
        component: _import('My'),
        name: 'My',
        meta: {
          auth: true,
          title: 'My'
        }
      }
    ]
  },
  {
    path: '/404',
    component: _import('404'),
    meta: {
      auth: false,
      title: 'Error'
    }
  },
  {
    path: '/401',
    component: _import('401'),
    meta: {
      auth: false,
      title: 'Error'
    }
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
