<template>
<section class="header" :class="{ 'fade-in-down': show }">
    <el-image class="logo" :src="$config.get('logo')" fit="contain" lazy></el-image>
    <ul class="menu-list">
        <li class="menu-item" @click="handleClick('Home')">
            <span :class="{ 'active': current === 'Home' }">{{ $t('nav.home') }}</span>
        </li>
        <li class="menu-item" @click="handleClick('Price')">
            <span :class="{ 'active': current === 'Price' }">{{ $t('nav.price') }}</span>
        </li>
        <li class="menu-item" @click="handleClick('Product')">
            <span :class="{ 'active': current === 'Product' }">{{ $t('nav.product') }}</span>
        </li>
        <li class="menu-item">
            <el-dropdown>
                <span :class="{ 'active': current === 'Resource' }" class="cus-h6">
                    {{ $t('nav.resourceCenter') }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <div class="cus-body" @click="jumpExternalPage('1863883454077337600')">
                            <span>{{ $t('nav.introductionGuide') }}</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div class="cus-body" @click="jumpExternalPage('1863883565616463872')">{{ $t('nav.commonProblem') }}</div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div class="cus-body" @click="jumpExternalPage('1863883715722215424')">{{ $t('nav.adsPowerAgentConfigurationTutorial') }}</div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div class="cus-body" @click="jumpExternalPage('1863883810739978240')">{{ $t('nav.adsPowerDynamicProxyConfigurationTutorial') }}</div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div class="cus-body" @click="jumpExternalPage('1863883876338892800')">{{ $t('nav.bitBrowserStaticProxyConfigurationTutorial') }}</div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </li>
        <!-- <li class="menu-item">推广赚佣金</li> -->
    </ul>
    <div class="cus-flex-x cus-flex-y-center">
        <el-dropdown @command="handleLanguageSwitch">
            <span class="cus-h6 cus-pointer">
                <svg-icon icon-class="qiehuanyuyan" style="width: 16px;height: 16px;" />
                <span style="margin-left: 6px;">{{ $t('header.language.' + $store.getters.lang) }}</span>
            </span>
            <!-- <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :disabled="$store.getters.lang === 'zh-TW'" command="zh-TW"><span class="cus-h6">{{
                                $t('header.language.zh-TW')
                                    }}</span></el-dropdown-item>
                <el-dropdown-item :disabled="$store.getters.lang === 'en'" command="en"><span class="cus-h6">{{
                                $t('header.language.en')
                                    }}</span></el-dropdown-item>
            </el-dropdown-menu> -->
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :disabled="$store.getters.lang === 'zh-TW'" command="zh-TW"><span class="cus-h6">{{
                                $t('header.language.zh-TW')
                                    }}</span></el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-link :underline="false" v-if="$store.getters.hasLogin" @click="handleClick('My')" class="cus-ml-5">{{ $store.getters.name }}</el-link>
        <div class="btn-box" v-if="!$store.getters.hasLogin">
            <el-button type="default" @click="$router.push({name: 'Login'})" v-if="!$store.getters.hasLogin">{{ $t('nav.login') }}</el-button>
            <el-button type="primary" @click="$router.push({name: 'Registration'})" v-if="!$store.getters.hasLogin">{{ $t('nav.registration') }}</el-button>
        </div>
        <el-image class="more" @click="drawer=!drawer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAAXNSR0IArs4c6QAAAA9QTFRFAAAANTU1MjIyMzMzMzMzB9e/YQAAAAR0Uk5TAD9/v2/dDxMAAAA2SURBVDjLY2AYBdQBLC4owIECCWZjFGBAV38wCqIAAVp4kEkJBSjQ1YM4LaeiB3EG4iigDAAAoIss1M+Rr6UAAAAASUVORK5CYII=" fit="contain"></el-image>
    </div>
    <el-drawer style="top: 60px" :with-header="true" size="100%" :modal="false" :visible.sync="drawer" direction="ltr" class="drawer-box">
        <ul class="drawer-menu-list">
            <li class="drawer-menu-item cus-maintitle">
                <div class="item-title">{{ $t('nav.price') }}</div>
            </li>
            <li class="drawer-menu-item cus-maintitle">
                <div class="item-title">{{ $t('nav.product') }}</div>
            </li>
            <li class="drawer-menu-item cus-maintitle">
                <el-dropdown class="cus-tile-x">
                    <div class="cus-maintitle cus-tile-x cus-flex-x cus-flex-y-center cus-flex-between">
                        <div>{{ $t('nav.resourceCenter') }}</div>
                        <div>+</div>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1852982284983074816')">
                                <!-- <svg-icon icon-class="changjianwenti" style="width: 16px;height: 16px;"></svg-icon> -->
                                <span>{{ $t('nav.introductionGuide') }}</span>
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1855877062884196352')">{{ $t('nav.commonProblem') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1852982284983074816')">{{ $t('nav.adsPowerAgentConfigurationTutorial') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1852982284983074816')">{{ $t('nav.adsPowerDynamicProxyConfigurationTutorial') }}</div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div class="cus-body" @click="jumpExternalPage('1852982284983074816')">{{ $t('nav.bitBrowserStaticProxyConfigurationTutorial') }}</div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </li>
        </ul>
        <div class="cus-flex-x box-wap">
            <el-button class="cus-flex-fill" type="default" @click="$router.push({name: 'Login'})" v-if="!$store.getters.hasLogin">{{ $t('nav.login') }}</el-button>
            <el-button class="cus-flex-fill" type="primary" @click="$router.push({name: 'Registration'})" v-if="!$store.getters.hasLogin">{{ $t('nav.registration') }}</el-button>
        </div>
    </el-drawer>

</section>
</template>

<script>
export default {
    name: 'Header',
    data() {
        return {
            show: false,
            drawer: false,
            current: 'Home'
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.current = route.name
            },
            immediate: true
        }
    },
    mounted() {
        this.current = this.$route.name
        window.addEventListener("scroll", this.handleScroll)
    },
    methods: {
        handleScroll() {
            console.log('scroll...')
            this.show = window.scrollY > 0
        },
        handleLanguageSwitch(lang) {
            this.$i18n.locale = lang;
            this.$store.commit("app/" + this.$mutationTypes.SET_LANGUAGE, lang);
        },
        jumpExternalPage(id) {
            this.current = 'Resource'
            this.$router.replace({
                name: 'Article',
                query: {
                    id
                }
            })
        },
        handleClick(e) {
            if (this.current !== e) {
                this.current = e
                this.$router.push({
                    name: e
                })
            } else {
                this.$router.go(0)
            }
        }
    },
}
</script>

<style lang="scss" scoped>
$--color-primary: #9E3AFF;

.header {
    position: fixed;
    z-index: 999;
    box-sizing: border-box;
    padding: 14px 40px;
    height: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;

    &.fade-in-down {
        background-color: #fff;
        animation-duration: .5s;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
        animation-name: fadeInDown;
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            transform: translate3d(0, -40px, 0);
        }

        100% {
            opacity: 1;
            transform: translateZ(0);
        }
    }

    .menu-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .menu-item {
            padding: 0 30px;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                color: $--color-primary;
            }

            .active {
                color: $--color-primary;
                border-bottom: 4px solid $--color-primary;
                padding-bottom: 14px;
            }

        }
    }

    .btn-box {
        margin-left: 20px;
    }

    &__nav {
        &--checked {
            background: #fff;
            transition: all .2s;
            box-shadow: 0px 3px 21.6px 2.4px rgba(60, 127, 255, 0.05);
        }
    }

    .logo {
        cursor: pointer;
        width: 104px;
        height: 72px;
    }

    .more {
        display: none;
    }

    @media screen and (min-width: 1025px) {
        .drawer-box {
            display: none !important;
        }
    }

    @media screen and (max-width: 1024px) {
        .menu-list {
            display: none;
        }

        .box-wap {
            margin-top: 20px;
            padding: 20px;
            position: fixed;
            width: 100%;
            bottom: 10px;
        }

        .drawer-menu-list {
            .drawer-menu-item {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 0 20px;
                padding: 10px 0;
                cursor: pointer;

                &:hover {
                    color: $--color-primary;
                }
            }
        }

        /deep/ .el-drawer__header {
            margin-bottom: 0 !important;
        }

        .btn-box {
            display: none
        }

        .logo {
            cursor: pointer;
            width: 4.983871rem;
            height: 2.625rem;
        }

        .more {
            display: inline-block;
            cursor: pointer;
            width: 2.625rem;
            height: 2.625rem;
        }
    }

}

@media screen and (max-width: 1024px) {
    .header {
        padding: 14px 10px !important;
        height: 70px !important;
    }
}
</style>
