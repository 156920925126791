<template>
<section class="footer">
    <div class="footer-wrapper">
        <el-row :gutter="20" type="flex" align="top">
            <el-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4">
                <div class="footer-item cus-text cus-flex-y cus-flex-start">
                    <el-image :src="require('@/assets/images/ipnova-logo.png')" fit="cover" :lazy="true" style="width: 131px;height: 90px;"></el-image>
                    <div class="cus-text-left cus-mt-8">{{ $t('footer.contactus') }}</div>
                    <div class="cus-text-left cus-mt-8">
                        <i class="el-icon-message"></i>
                        <span class="cus-mx-2">{{$t('footer.email')}}：</span>
                        <span>ipinsta@163.com</span>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5">
                <div class="footer-item cus-flex-x cus-flex-end">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.product') }}</div>
                        <div class="cus-body article-item" @click="jumpExternalPage(articleItem.id)" v-for="(articleItem, articleIndex) in articleArr['product']" :key="articleIndex">
                            <span>{{ $t(articleItem.articleTitle) }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5">
                <div class="footer-item cus-flex-x cus-flex-end">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.resourceService') }}</div>
                        <div class="cus-body article-item" @click="$router.push({name: 'Price'})">
                            <span>{{ $t('footer.price') }}</span>
                        </div>
                        <div class="cus-body article-item" @click="jumpExternalPage('1863882012847374336')">
                            <span>{{ $t('footer.QA') }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5">
                <div class="footer-item cus-flex-x cus-flex-end">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.useCase') }}</div>
                        <div class="cus-body article-item" @click="jumpExternalPage(articleItem.id)" v-for="(articleItem, articleIndex) in articleArr['useCase']" :key="articleIndex">
                            <span>{{ $t(articleItem.articleTitle) }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="12" :lg="5" :xl="5">
                <div class="footer-item cus-flex-x cus-flex-end">
                    <div class="article-box">
                        <div class="cus-h6 title">{{ $t('footer.legalDisclaimer') }}</div>
                        <div class="cus-body article-item" @click="jumpExternalPage(articleItem.id)" v-for="(articleItem, articleIndex) in articleArr['legalDisclaimer']" :key="articleIndex">
                            <span>{{ $t(articleItem.articleTitle) }}</span>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="box cus-caption cus-flex-x cus-flex-xy-center">
            <span class="cus-mr-5">@2024 广州因斯塔信息科技有限公司</span>
            <el-link type="info" href="https://beian.miit.gov.cn/#/Integrated/index" :underline="false">粤ICP备2024348909号-1</el-link>
        </div>
    </div>
</section>
</template>

<script>
import {
    articleArr
} from '@/common/data/footer'

export default {
    name: 'Footer',
    data() {
        return {
            articleArr
        }
    },
    methods: {
        jumpExternalPage(id) {
            this.$router.replace({
                name: 'Article',
                query: {
                    id
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.footer {
    background: #222153;
    padding-top: 90px;

    .article-box {
        text-align: left;

        .title {
            color: #C5BBD3;
            margin-bottom: 18px;
        }

        .article-item {
            color: #8C87A9;
            margin-bottom: 14px;
            cursor: pointer;
        }
    }

    .cus-text {
        font-weight: 400;
        font-size: 13px;
        color: #C5BBD3;
    }

    &-wrapper {
        max-width: 1480px;
        box-sizing: border-box;
        margin: 0 auto;

        /deep/ .el-row {
            border-bottom: 1px solid #8C87A9;
            padding-bottom: 54px;
        }

        @media screen and (max-width: 1202px) {
            .footer-item {
                justify-content: center !important;
                align-items: center !important;
                margin-bottom: 20px;
            }
        }

        .box {
            color: #8C87A9;
            padding: 36px 0;
        }
    }
}
</style>
