// ** 本文件是config.js的默认数据 (请勿修改本文件中的内容)
// ** 如需修改配置请移步到根目录的config.js文件
export default {

  // 系统名称
  title: "IP-INSTAL",

  logo: '',

  /**
   * 是否启用门户端设置缓存
   * 将减少用户端重复请求; 正式运营时请设为true, 开启后门户端设置同步前端需10分钟缓存
   */
  enabledSettingCache: true

}
