import Vue from 'vue';

import * as commonFilter from './common.js';

const filters = {
	...commonFilter
}

// 添加业务公共filter
Object.keys(filters).map(v => {
	Vue.filter(v, filters[v])
})