export const articleArr = {
    product: [
        {
            id: '1863854224018898944',
            articleTitle: 'footer.dynamicProxy',
        },
        {
            id: '1863872719242788864',
            articleTitle: 'footer.ipv4'
        },
        {
            id: '1863872795767865344',
            articleTitle: 'footer.ipv6'
        },
        {
            id: '1863872858829225984',
            articleTitle: 'footer.staticISP'
        }
    ],
    useCase: [
        {
            id: '1863881668671176704',
            articleTitle: 'footer.useCase'
        }
    ],
    legalDisclaimer: [
        {
            id: '1863882332277178368',
            articleTitle: 'footer.serviceClause'
        },
        {
            id: '1863882400065519616',
            articleTitle: 'footer.lawAndPrivacy'
        }
    ]
}