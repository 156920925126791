import Vue from 'vue'
import '@/icons'
import 'normalize.css/normalize.css'
import './permission'
import '@/styles/index.scss'
import '@/styles/setting/element-variables.scss'
import i18n from './lang'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import Cookies from 'js-cookie'
import VueClipboard from 'vue-clipboard2'
import config from '@/core/config/index'
import '@/filters/index.js'
import * as mutationTypes from '@/store/mutation-type'
Vue.use(VueClipboard)


Vue.config.productionTip = false
Vue.prototype.$config = config
Vue.prototype.$mutationTypes = mutationTypes

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

Vue.directive('auth-required', {
  inserted(el, binding, vnode) {
      if (!store.getters.hasLogin) {
          router.push({ name: 'Login' }); // 如果未登录，跳转到名为'Login'的路由，名字根据实际路由配置调整
      }
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')