export default {

  // 系统名称
  title: "IP-INSTAL",

  logo: require('@/assets/images/logo.png'),

  /**
   * 是否启用门户端设置缓存
   * 将减少用户端重复请求; 正式运营时请设为true, 开启后门户端设置同步前端需10分钟缓存
   */
  enabledSettingCache: true

}